import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMessage, getPushMessage, listMessage } from '@/api/message';
export default {
  components: {},
  data: function data() {
    return {
      // 总条数
      total: 0,
      // 表格数据
      messageList: [],
      // 查询参数
      queryParams: {
        status: 0
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    var users = JSON.parse(window.localStorage.getItem('datas')); //
    if (users.permissions.includes("message:getPushMessage") || users.permissions.includes("*:*:*")) {
      this.getPushList(true);
      var timer = setInterval(function () {
        _this.getPushList(false);
      }, 8000);
      this.$once('hook:beforeDestroy', function () {
        return clearInterval(timer);
      });
    }
  },
  methods: {
    /** 获取消息列表 */getList: function getList() {
      var _this2 = this;
      listMessage(this.addDateRange(this.queryParams)).then(function (response) {
        if (response === undefined) {
          return;
        }
        _this2.messageList = response.data.list;
        _this2.total = response.data.total;
      });
    },
    messageDispose: function messageDispose(row) {
      getMessage(row.id).then(function (response) {
        if (response === undefined) {
          return;
        }
        window.open(row.location, '_blank');
      });
    },
    // 未读消息推送
    getPushList: function getPushList(status) {
      var _this3 = this;
      // this.$refs.audio.currentTime = 0; //从头开始播放提示音
      // this.$refs.audio.play(); //播放
      var that = this;
      getPushMessage(this.addDateRange(this.queryParams)).then(function (response) {
        if (response === undefined) {
          return;
        }
        if (status || response.data.total > 0) {
          that.getList();
        }
        if (response.data.total > 0) {
          //消息推送
          var h = that.$createElement;
          response.data.list.forEach(function (value, key) {
            that.$notify({
              title: value.title,
              message: h('i', {
                style: 'color: teal'
              }, value.content)
            });
          });
          _this3.$refs.audio.currentTime = 0.8; //从头开始播放提示音
          _this3.$refs.audio.play(); //播放
        }
      });
    }
  }
};