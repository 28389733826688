import _createForOfIteratorHelper from "E:/chenkai20230828/x-admin-frontend/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _typeof from "E:/chenkai20230828/x-admin-frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params;
  search.beginTime = '';
  search.endTime = '';
  if (dateRange != null && dateRange !== '') {
    search.beginTime = this.dateRange[0];
    search.endTime = this.dateRange[1];
  }
  return search;
}
export function in_array(search, array) {
  for (var i in array) {
    // eslint-disable-next-line eqeqeq
    if (array[i] == search) {
      return true;
    }
  }
  return false;
}

// 回显数据字典
export function selectItemLabelV3(datas, value, k, v) {
  var actions = [];
  if (!value) {
    return '';
  }
  value = value + '';
  var valueArray = value.split(',');
  Object.keys(datas).map(function (key) {
    if (in_array(datas[key][k], valueArray)) {
      actions.push(datas[key][v]);
      return false;
    }
  });
  return actions.join(',');
}
// 关闭当前标签页
export function closePage() {
  var _this = this;
  var currentView = this.$store.state.tagsView.visitedViews[0];
  var _iterator = _createForOfIteratorHelper(this.$store.state.tagsView.visitedViews),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      currentView = _step.value;
      if (currentView.path === this.$route.path) {
        break;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  this.$store.dispatch('tagsView/delView', currentView).then(function (_ref) {
    var visitedViews = _ref.visitedViews;
    if (currentView.path === _this.$route.path) {
      var latestView = _this.$store.state.tagsView.visitedViews.slice(-1)[0];
      if (latestView) {
        _this.$router.push(latestView);
      } else {
        // 如果没有其他标签则跳转到首页
        if (currentView.name === '首页') {
          _this.$router.replace({
            path: '/redirect' + currentView.fullPath
          });
        } else {
          _this.$router.push('/');
        }
      }
    }
  });
}

// 回显数据字典
export function selectItemLabelV2(datas, value, k, v) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key][k] + '' === '' + value) {
      actions.push(datas[key][v]);
      return false;
    }
  });
  return actions.join('');
}
// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].dict_value === '' + value) {
      actions.push(datas[key].dict_label);
      return false;
    }
  });
  return actions.join('');
}
export function selectItemsLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].key === '' + value) {
      actions.push(datas[key].value);
      return false;
    }
  });
  return actions.join('');
}

// 字符串格式化(%s )
export function sprintf(str) {
  var args = arguments;
  var flag = true;
  var i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];
    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }
    return arg;
  });
  return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return '';
  }
  return str;
}

// 通用下载方法
export function download(fileName) {
  window.location.href = baseURL + '/common/download?fileName=' + encodeURI(fileName) + '&delete=' + true;
}
export function dateFormat(fmt, date) {
  var ret = '';
  date = new Date(date);
  var opt = {
    'Y+': date.getFullYear().toString(),
    // 年
    'm+': (date.getMonth() + 1).toString(),
    // 月
    'd+': date.getDate().toString(),
    // 日
    'H+': date.getHours().toString(),
    // 时
    'M+': date.getMinutes().toString(),
    // 分
    'S+': date.getSeconds().toString() // 秒
  };
  for (var k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}

// 复制开票信息
export function copyInvoice(data) {
  console.log('copyTxt', data);
  var subs_sum = "累计开票金额：" + (parseFloat(data.sub_sum / 100).toFixed(2) + "元；" + '\n');
  var record_type = "发票类型：" + ((data.record_type == 1 ? "电子发票；" : "纸质发票；") + '\n');
  var record_head = "发票抬头：" + ((data.record_head == 1 ? "单位；" : "个人；") + '\n');
  var record_name = "个人姓名：" + (data.record_name + '；' + '\n');
  var record_address = "邮寄地址：" + (data.record_address + '；' + '\n');
  var record_mobile = "手机号：" + (data.record_mobile + '；' + '\n');
  var record_items = '';
  var record_items_name = '';
  var record_items_amount = '';
  // console.log('1111',data.invoice_items)
  if (data.invoice_items != undefined && data.invoice_items != null) {
    data.invoice_items.forEach(function (item) {
      record_items_name = "发票项目：" + (item.name + '；' + '\n');
      record_items_amount = "发票金额：" + (parseFloat(item.amount / 100).toFixed(2) + '元；' + '\n');
      record_items += record_items_name + record_items_amount;
    });
  }
  var no = "开票编号：" + (data.no + '；' + '\n');
  var is_concat = "发票联号：" + ((data.is_concat ? '是' : '否') + '；' + '\n');
  var is_report = "报名通知：" + ((data.is_report ? '是' : '否') + '；' + '\n');
  var is_detail = "服务明细展示：" + ((data.is_detail ? '是' : '否') + '；' + '\n');
  var remark = "备注说明：" + (data.remark + '；' + '\n');
  var copydata = subs_sum + no + record_type + record_head + record_name + (data.record_type == 1 ? "接收邮箱：" + data.record_mail + '；' + '\n' : record_address) + record_mobile + record_items + is_concat + is_report + (data.is_report ? "通知邮箱：" + data.record_mail2 + '；' + '\n' : "") + is_detail + remark;
  this.$copyText(copydata);
  this.$message({
    message: '复制成功',
    type: 'success'
  });
}
export function downloadFile(url) {
  var a = document.createElement("a"); //创建一个标签
  a.href = url;
  // a.setAttribute("download", filename); // 添加downLoad属性
  // a.download = filename; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
  a.style.display = "none"; // 障眼法藏起来a标签
  document.body.appendChild(a); // 将a标签追加到文档对象中
  a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
  a.remove(); // 一次性的，用完就删除a标签 
}