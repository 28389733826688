import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { login as _login, logout as _logout, getInfo as _getInfo } from '@/api/user';
import { loginByPhoneAndCode as _loginByPhoneAndCode, getQrResult as _getQrResult } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permisaction: [],
    datas: {}
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    console.log(state, 'user');
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permisaction) {
    state.permisaction = permisaction;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_DATAS: function SET_DATAS(state, datas) {
    state.datas = datas;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _login(userInfo).then(function (response) {
        // const { data } = response
        var token = response.data.token;
        // console.log(response.data.user)
        window.localStorage.setItem('datas', JSON.stringify(response.data.user));
        commit('SET_TOKEN', token);
        setToken(token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user login
  loginByPhoneAndCode: function loginByPhoneAndCode(_ref2, userInfo) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _loginByPhoneAndCode(userInfo).then(function (response) {
        console.log('response', response);
        // const { data } = response
        var token = response.data.token;
        // console.log(response.data.user)
        window.localStorage.setItem('datas', JSON.stringify(response.data.user));
        commit('SET_TOKEN', token);
        setToken(token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getQrResult: function getQrResult(_ref3, key) {
    var commit = _ref3.commit;
    console.log('getQrResult-key', key);
    return new Promise(function (resolve, reject) {
      _getQrResult(key).then(function (response) {
        console.log('getQrResult-res', response);
        if (response.code === 0) {
          // const { data } = response
          var token = response.data.token;
          // console.log(response.data.user)
          window.localStorage.setItem('datas', JSON.stringify(response.data.user));
          commit('SET_TOKEN', token);
          setToken(token);
        }
        resolve(response);
      }).catch(function (error) {
        console.log('getQrResult-error', error);
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (response) {
        var data = response.data;
        if (!data) {
          return reject('Verification failed, please Login again.');
        }
        var roleNames = [];
        // let permission = ['*:*:*']
        // let permission = []
        data.roles.forEach(function (item) {
          roleNames.push(item.tag);
        });
        var userName = data.userName,
          avatar = data.avatar;
        window.localStorage.setItem('defMenu', JSON.stringify(response.data.roles[0]));
        commit('SET_NAME', userName);
        commit('SET_AVATAR', avatar);
        commit('SET_ROLES', roleNames);
        if (data.permissions && data.permissions.length > 0) {
          commit('SET_PERMISSIONS', data.permissions);
        }
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        window.localStorage.removeItem('datas');
        commit('SET_TOKEN', '');
        commit('RESET_STATE');
        removeToken(); // must remove  token  first
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};