import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//

import pathToRegexp from 'path-to-regexp';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];
      if (!this.isDashboard(first)) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: ''
          }
        }].concat(matched);
      }
      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      // const { redirect, path } = item
      // if (redirect) {
      //   this.$router.push(redirect)
      //   return
      // }
      // this.$router.push(this.pathCompile(path))
    }
  }
};