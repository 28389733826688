import _interopRequireWildcard from "E:/chenkai20230828/x-admin-frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  hidden: true,
  redirect: '/login',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: false
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: {
      title: '个人中心',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/dict',
  component: Layout,
  hidden: true,
  children: [{
    path: 'dataList/:dictType',
    component: function component(resolve) {
      return require(['@/views/dict/dataList'], resolve);
    },
    name: 'Data',
    meta: {
      title: '字典数据',
      icon: ''
    }
  }]
}, {
  path: '/report',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/report/add'], resolve);
    },
    name: 'reportAdd',
    meta: {
      title: '报告页面',
      icon: ''
    }
  }]
}, {
  path: '/page/scale',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/scale/add'], resolve);
    },
    name: 'scaleAdd',
    meta: {
      title: '量表库新增'
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/scale/add'], resolve);
    },
    name: 'scaleEdit',
    meta: {
      title: '量表库修改'
    }
  }]
}, {
  path: '/page/theory',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/theory/add'], resolve);
    },
    name: 'theoryAdd',
    meta: {
      title: '理论应用新增'
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/theory/add'], resolve);
    },
    name: 'theoryEdit',
    meta: {
      title: '理论应用修改'
    }
  }]
}, {
  path: '/page/topic',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/topic/add'], resolve);
    },
    name: 'topicAdd',
    meta: {
      title: '选题新增'
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/topic/add'], resolve);
    },
    name: 'topicEdit',
    meta: {
      title: '选题修改'
    }
  }, {
    path: 'audit',
    component: function component(resolve) {
      return require(['@/views/topic/audit'], resolve);
    },
    name: 'topicAudit',
    meta: {
      title: '选题审核'
    }
  }]
}, {
  path: '/page/crm/client',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/crm/client/add'], resolve);
    },
    name: 'clientAdd',
    meta: {
      title: '客户新增'
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/crm/client/add'], resolve);
    },
    name: 'clientEdit',
    meta: {
      title: '客户编辑'
    }
  }]
}, {
  path: '/page/tools-post/sys/problem',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/tools-post/sys/problem/add'], resolve);
    },
    name: 'tools_problem_add',
    meta: {
      title: '添加常见问题',
      icon: ''
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/tools-post/sys/problem/add'], resolve);
    },
    name: 'tools_problem_edit',
    meta: {
      title: '编辑常见问题',
      icon: ''
    }
  }]
}, {
  path: '/workflow/flowlist',
  component: Layout,
  hidden: true,
  children: [{
    path: 'designflow',
    component: function component(resolve) {
      return require(['@/views/workflow/flowlist/designflow'], resolve);
    },
    name: 'flowDesign',
    meta: {
      title: '流程设计'
    }
  }]
}, {
  path: '/pc/article',
  component: Layout,
  hidden: true,
  children: [{
    path: 'artAdd',
    component: function component(resolve) {
      return require(['@/views/pc/article/artAdd'], resolve);
    },
    name: 'artAdd',
    meta: {
      title: '写作页面',
      icon: ''
    }
  }]
}, {
  path: '/pc/commonwt',
  component: Layout,
  hidden: true,
  children: [{
    path: 'wtadd',
    component: function component(resolve) {
      return require(['@/views/pc/cjwt/wtadd'], resolve);
    },
    name: 'wtadd',
    meta: {
      title: '添加常见问题',
      icon: ''
    }
  }]
}, {
  path: '/pc/videos',
  component: Layout,
  hidden: true,
  children: [{
    path: 'addcourse',
    component: function component(resolve) {
      return require(['@/views/pc/videos/addcourse'], resolve);
    },
    name: 'addcourse',
    meta: {
      title: '课程发布',
      icon: ''
    }
  }]
}, {
  path: '/page/share',
  component: Layout,
  hidden: true,
  children: [{
    path: 'withdrawMoney',
    component: function component(resolve) {
      return require(['@/views/share/withdrawMoney'], resolve);
    },
    name: 'shareWithdrawMoney',
    meta: {
      title: '提现打款'
    }
  }, {
    path: 'draw',
    component: function component(resolve) {
      return require(['@/views/share/draw'], resolve);
    },
    name: 'draw',
    meta: {
      title: '开票信息管理'
    }
  }, {
    path: 'agentManage',
    component: function component(resolve) {
      return require(['@/views/share/agentManage'], resolve);
    },
    name: 'agentManage',
    meta: {
      title: '代理商管理'
    }
  }, {
    path: 'shareRecord',
    component: function component(resolve) {
      return require(['@/views/share/shareRecord'], resolve);
    },
    name: 'shareRecord',
    meta: {
      title: '用户分享记录'
    }
  }, {
    path: 'auditWithdraw',
    component: function component(resolve) {
      return require(['@/views/share/auditWithdraw'], resolve);
    },
    name: 'auditWithdraw',
    meta: {
      title: '审核提现'
    }
  }, {
    path: 'auditCompany',
    component: function component(resolve) {
      return require(['@/views/share/auditCompany'], resolve);
    },
    name: 'auditCompany',
    meta: {
      title: '审核公司'
    }
  }, {
    path: 'commentBack',
    component: function component(resolve) {
      return require(['@/views/share/commentBack'], resolve);
    },
    name: 'commentBack',
    meta: {
      title: '意见反馈'
    }
  }, {
    path: 'userList',
    component: function component(resolve) {
      return require(['@/views/share/userList'], resolve);
    },
    name: 'userList',
    meta: {
      title: '用户列表'
    }
  }, {
    path: 'orderList',
    component: function component(resolve) {
      return require(['@/views/share/orderList'], resolve);
    },
    name: 'orderList',
    meta: {
      title: '订单列表'
    }
  }, {
    path: 'noticeManage',
    component: function component(resolve) {
      return require(['@/views/share/noticeManage'], resolve);
    },
    name: 'noticeManage',
    meta: {
      title: '公告管理'
    }
  }, {
    path: 'addGoods',
    component: function component(resolve) {
      return require(['@/views/share/components/addGoods'], resolve);
    },
    name: 'addGoods',
    meta: {
      title: '添加商品',
      icon: ''
    }
  }, {
    path: 'buildGoods',
    component: function component(resolve) {
      return require(['@/views/share/components/buildGoods'], resolve);
    },
    name: 'buildGoods',
    meta: {
      title: '新建商品',
      icon: ''
    }
  }, {
    path: 'goodsIntr',
    component: function component(resolve) {
      return require(['@/views/share/components/goodsIntr'], resolve);
    },
    name: 'goodsIntr',
    meta: {
      title: '商品介绍',
      icon: ''
    }
  }, {
    path: 'groupMoneySet',
    component: function component(resolve) {
      return require(['@/views/share/groupMoneySet'], resolve);
    },
    name: 'groupMoneySet',
    meta: {
      title: '组佣金管理'
    }
  }, {
    path: 'levelSet',
    component: function component(resolve) {
      return require(['@/views/share/levelSet'], resolve);
    },
    name: 'levelSet',
    meta: {
      title: '定制等级设置'
    }
  }, {
    path: 'commissionSet',
    component: function component(resolve) {
      return require(['@/views/share/commissionSet'], resolve);
    },
    name: 'commissionSet',
    meta: {
      title: '佣金设置'
    }
  }, {
    path: 'oneLevelSet',
    component: function component(resolve) {
      return require(['@/views/share/oneLevelSet'], resolve);
    },
    name: 'oneLevelSet',
    meta: {
      title: '一级等级设置'
    }
  }, {
    path: 'hotWords',
    component: function component(resolve) {
      return require(['@/views/share/hotWords'], resolve);
    },
    name: 'hotWords',
    meta: {
      title: '热词设置'
    }
  }, {
    path: 'addAgency',
    component: function component(resolve) {
      return require(['@/views/share/addAgency'], resolve);
    },
    name: 'addAgency',
    meta: {
      title: '添加代理商'
    }
  }, {
    path: 'agentDetails',
    component: function component(resolve) {
      return require(['@/views/share/components/agentDetails'], resolve);
    },
    name: 'agentDetails',
    meta: {
      title: '代理商详情',
      icon: ''
    }
  }, {
    path: 'insideBanner',
    component: function component(resolve) {
      return require(['@/views/share/components/insideBanner'], resolve);
    },
    name: 'insideBanner',
    meta: {
      title: '内部广告管理',
      icon: ''
    }
  }, {
    path: 'agentBanner',
    component: function component(resolve) {
      return require(['@/views/share/components/agentBanner'], resolve);
    },
    name: 'agentBanner',
    meta: {
      title: '代理商首页banner管理',
      icon: ''
    }
  }]
}, {
  path: '/invoice/basicSet',
  component: Layout,
  hidden: true,
  children: [{
    path: 'regNotice',
    component: function component(resolve) {
      return require(['@/views/invoice/basicSet/productRegNotice/components/regNotice'], resolve);
    },
    name: 'regNotice',
    meta: {
      title: '产品报名通知'
    }
  }, {
    path: 'courseFee',
    component: function component(resolve) {
      return require(['@/views/invoice/basicSet/productRegNotice/components/courseFee'], resolve);
    },
    name: 'courseFee',
    meta: {
      title: '产品报名课程收费'
    }
  }, {
    path: 'upgrade_order',
    component: function component(resolve) {
      return require(['@/views/invoice/components/upgrade_order'], resolve);
    },
    name: 'upgrade_order',
    meta: {
      title: '升级订单'
    }
  }, {
    path: 'modify_order',
    component: function component(resolve) {
      return require(['@/views/invoice/components/modify_order'], resolve);
    },
    name: 'modify_order',
    meta: {
      title: '修改订单'
    }
  }, {
    path: 'supply_payment',
    component: function component(resolve) {
      return require(['@/views/invoice/components/supply_payment'], resolve);
    },
    name: 'supply_payment',
    meta: {
      title: '补充支付'
    }
  }, {
    path: 'look_up',
    component: function component(resolve) {
      return require(['@/views/invoice/components/look_up'], resolve);
    },
    name: 'look_up',
    meta: {
      title: '查看订单详情'
    }
  }, {
    path: 'add_edit_order',
    component: function component(resolve) {
      return require(['@/views/invoice/components/add_edit_order'], resolve);
    },
    name: 'add_edit_order',
    meta: {
      title: '新增/修改/查看订单'
    }
  }, {
    path: 'addrefund',
    component: function component(resolve) {
      return require(['@/views/invoice/applyRefund/components/addrefund'], resolve);
    },
    name: 'addrefund',
    meta: {
      title: '新增/修改/查看订单'
    }
  }]
}];
export var asyncRoutes = [];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;