import _objectSpread from "E:/chenkai20230828/x-admin-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ChartCard from '../../components/ChartCard';
import Trend from '../../components/Trend';
import MiniArea from '../../components/MiniArea';
import MiniBar from '../../components/MiniBar';
import MiniProgress from '../../components/MiniProgress';
import Cell from '../../components/Cell/index';
import { getServerInfo as _getServerInfo } from '../../api/monitor';
import moment from 'moment';
var miniData = [];
var beginDay = new Date().getTime();
for (var i = 0; i < 20; i++) {
  miniData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
    y: Math.round(Math.random() * 10)
  });
}
export default {
  name: 'Dashboard',
  components: {
    ChartCard: ChartCard,
    Trend: Trend,
    MiniArea: MiniArea,
    MiniBar: MiniBar,
    MiniProgress: MiniProgress,
    Cell: Cell
  },
  data: function data() {
    return {
      miniData: miniData,
      count: 0,
      data: [],
      busy: false,
      serverInfo: {}
    };
  },
  computed: _objectSpread({}, mapGetters(['name'])),
  created: function created() {
    this.getServerInfo();
  },
  methods: {
    getServerInfo: function getServerInfo() {
      var _this = this;
      _getServerInfo().then(function (response) {
        if (response === undefined) {
          return;
        }
        _this.serverInfo = response.data;
      });
    }
  }
};