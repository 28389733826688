import _objectSpread from "E:/chenkai20230828/x-admin-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { asyncRoutes, constantRoutes } from '@/router';
import { getRoutes } from '@/api/role';
import Layout from '@/layout';
// import sysuserindex from '@/views/sysuser/index'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Use names to determine if the current user has permission
 * @param names
 * @param route
 */
function hasPathPermission(paths, route) {
  if (route.path) {
    return paths.some(function (path) {
      return route.path === path.path;
    });
  } else {
    return true;
  }
}

/**
  * 后台查询的菜单数据拼装成路由格式的数据
  * @param routes
  */
export function generaMenu(routes, data) {
  data.forEach(function (item) {
    // 主菜单
    if (item.children.length === 0 && item.parentId === 0) {
      var menu = {
        path: item.path,
        component: Layout,
        hidden: false,
        children: [{
          path: item.path,
          name: item.name,
          component: loadView(item.component),
          meta: {
            title: item.name,
            icon: item.icon
          }
        }]
      };
    } else {
      var menu = {
        path: item.path,
        component: item.component === 'Layout' ? Layout : loadView(item.component),
        hidden: false,
        children: [],
        name: item.name,
        meta: {
          title: item.name,
          icon: item.icon,
          noCache: true
        }
      };
    }
    if (item.children) {
      generaMenu(menu.children, item.children);
    }
    routes.push(menu);
  });
}
export var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(['@/views' + view], resolve);
  };
  // return () => import(`@/views${view}`)
};

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param components
 */
export function filterAsyncPathRoutes(routes, paths) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPathPermission(paths, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncPathRoutes(tmp.children, paths);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref) {
    var _this = this;
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var loadMenuData = [];
      getRoutes().then(function (response) {
        var data = response;
        if (response.code !== 200) {
          _this.$message({
            message: '菜单数据加载异常',
            type: 0
          });
        } else {
          data = response.data;
          Object.assign(loadMenuData, data);
          generaMenu(asyncRoutes, loadMenuData);
          asyncRoutes.push({
            path: '*',
            redirect: '/',
            hidden: true
          });
          commit('SET_ROUTES', asyncRoutes);
          resolve(asyncRoutes);
        }
      }).catch(function (error) {
        console.log(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};